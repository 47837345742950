<!--
 * @Description: 资源管理
 * @Author: LiangYiNing
 * @Date: 2022-02-10 14:36:30
 * @LastEditTime: 2022-07-15 16:44:35
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize"> </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          row-key="id"
          :expand-row-keys="expandIds"
          :tree-props="{ children: 'child' }"
          class="elTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :max="3"
                :data="getOperateList(scope.row)"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->
    </div>
    <addOrUpdate ref="addOrUpdate" @queryList="queryList" />
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { fetchAllModuleTree, findDownList, deleteModule } from "@/api";
import { printError } from "@/utils/util";
import addOrUpdate from "./addOrUpdate.vue";
export default {
  name: "resourceManagement",
  components: { addOrUpdate },
  data() {
    return {
      total: 0,
      expandIds: ["0"],
      timePickerResetList: ["createTime"], // base mixin的配置项 需要重置的timePicker refname
      businessCodeEnum: [], // 导出业务类型枚举
      invalidStatusEnum: [
        { id: 0, label: "失效" },
        { id: 1, label: "有效" }
      ],
      queryListAPI: fetchAllModuleTree,
      searchForm: {
        businessCode: "", //导出业务类型
        invalidStatus: "", //失效状态（0失效，1有效）
        createTime: [], //创建时间
        startTime: "", //开始时间
        endTime: "", //结束时间
        pageIndex: 1,
        pageSize: 20,
        sortIndex: "createdTime", // 默认创建时间排序
        sortDir: "descending" // 默认降序
      },
      columnData: [
        {
          fieldName: "moduleName",
          display: true,
          fieldLabel: "资源名称",
          width: 260,
          disabled: false,
          fixed: false,
          align: "left"
        },
        {
          fieldName: "icon",
          display: true,
          fieldLabel: "图标",
          width: 160,
          disabled: false,
          fixed: false,
          align: "left"
        },
        {
          fieldName: "rowNo",
          display: true,
          fieldLabel: "排序",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "modulePath",
          display: true,
          fieldLabel: "资源路径",
          width: 160,
          disabled: false,
          fixed: false,
          align: "left"
        },
        {
          fieldName: "moduleComponent",
          display: true,
          fieldLabel: "vue注册路径",
          width: 160,
          disabled: false,
          fixed: false,
          align: "left"
        },
        {
          fieldName: "componentName",
          display: true,
          fieldLabel: "组件名",
          width: 160,
          disabled: false,
          fixed: false,
          align: "left"
        },
        {
          fieldName: "isShow",
          display: true,
          fieldLabel: "是否显示菜单",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "isCache",
          display: true,
          fieldLabel: "是否缓存",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "isenable",
          display: true,
          fieldLabel: "状态",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTime",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [{}]
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.startTime = val[0] || "";
        this.searchForm.endTime = val[1] || "";
      }
    }
  },
  created() {
    this.queryList();
    this.initData();
  },
  methods: {
    async queryList() {
      if (!this.queryListAPI) {
        return;
      }
      try {
        this.loading = true;
        let res = await this.queryListAPI(this.searchForm);
        if (res.code !== "OK") {
          this.tableData = [];
          return;
        }
        let data = res.data;
        this.tableData = [data];
        this.initParentName(this.tableData);
        this.expandIds = this.tableData.map(item => item.id);
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取枚举信息
    async initData() {
      try {
        let enumKey = ["businessCode"];
        //获取枚举数据
        let promiseList = [findDownList(enumKey)];
        let [findDownListRes] = await Promise.all(promiseList);
        enumKey.map(item => {
          this[`${item}Enum`] = this.getFreezeResponse(findDownListRes, {
            path: `data.${item}`
          });
        });
      } catch (error) {
        printError(error);
      }
    },
    // 表格勾选
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return item.simPropId;
      });
    },
    // 获取页面操作权限
    getOperateList(row) {
      // 页面按钮权限
      let operates = [
        { label: "新增", id: 1 },
        { label: "修改", id: 2 },
        { label: "删除", id: 3 }
      ];
      switch (row.moduleType) {
        case 1:
        case 2:
          break;
        case 3:
          operates = operates.slice(1, 3);
          break;
        default:
          operates = operates.slice(0, 1);
      }
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      switch (val.id) {
        case 1:
          this.openAddOrUpdate(row, "ADD");
          break;
        case 2:
          this.openAddOrUpdate(row, "EDIT");
          break;
        case 3:
          this.deleteModule(row);
          break;
      }
    },
    // 洗一下数据 增加父节点名称
    initParentName(data, pName = "") {
      data.map(item => {
        item.parentMenu = pName;
        if (item.child && item.child.length) {
          this.initParentName(item.child, item.moduleName);
        }
      });
    },
    //打开编辑页
    openAddOrUpdate(row, handleType) {
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(row, handleType);
      });
    },
    // 删除
    deleteModule(row) {
      this.$confirm(`是否确认删除资源?`, `删除资源`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          try {
            this.loading = true;
            let res = await deleteModule({
              id: row.id,
              versionNumber: row.versionNumber
            });
            if (res.code === "OK") {
              this.$message.success("删除成功");
              this.queryList();
            }
          } catch (error) {
            printError(error);
          } finally {
            this.loading = false;
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: `已取消`
          });
        });
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .edit-customer-dialog {
  .el-form {
    padding: 20px 20px 0;
    .el-input {
      width: 350px;
    }
  }
}
</style>
